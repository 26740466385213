// This file can be replaced during build by using the `fileReplacements` array.

export const environment = {
    accopeningUserPassAuthCheck: new Map([
        [
            'CLIENTWEB',
            [
                ['accOpeningUsername', 'XdLJIngSRc5/lFGNrEkvV18lzz6nMZ8rTVK5m6StxJBp/dGVEGLCnS5IcrzJoR7r'],
                ['accOpeningPassWord', 'XdLJIngSRc5/lFGNrEkvVymUS3IpEXgzL0L91YPcXhFfB3N503qKStn08cXO+rRd'],
                ['AuthrizationKey', 'Sacam3aeve4afgeqdxbeb8fce220212121'],
                ['checkSumKey', '40817ZXag@Exzheo!9477']
            ]
        ],
        [
            'SPOT',
            [
                ['accOpeningUsername', 'jr6kYSUDFmgS3nfUGl63dW0Kxefq0URmqUlmMDK0+Bw='],
                ['accOpeningPassWord', '5TFFze0LcBosjcljXL1RXs/vaY3zGBw9lWJ+S5eaEYE='],
                ['AuthrizationKey', 'AHxwqzO2naLu9FW758aMdobHZGdvE8p6'],
                ['checkSumKey', 'CqW%MsuNVo!Gghlq']
            ]
        ],
        [
            'SPOTIOS',
            [
                ['accOpeningUsername', 'ix2OYeXODrPZukzBqyrfyspPmYlua530jU5o3thLftk='],
                ['accOpeningPassWord', '0YgAtI4tdAdkb6uqk9sbgLpn8kiYaqhZPGCJ+P4a8PI='],
                ['AuthrizationKey', 'YbkQXgL9eMbRaZK7s3uPkiDaMG2cdBrW'],
                ['checkSumKey', 'gHqBe@y!M%@jNlLd']
            ]
        ],
        [
            'PARTNERSHIPS',
            [
                ['accOpeningUsername', '+PYoI4pXclHqdFlg9OVKRUTPt5oqbJSqepNO+Mo0eb0='],
                ['accOpeningPassWord', '4/ntgW/z1Chi91Lfr8i9P5fGfUcMMC7BgGQT6Ek0QEw='],
                ['AuthrizationKey', 'XQ8VNrbbcKcOd8UW263jVx8aTCYUO3p6'],
                ['checkSumKey', '1401P!BYQVNr26mRafad']
            ]
        ]
    ]),
    production: true,
    twoFACheck: 'Y',
    appVer: '1.0',
    ttAPIHeaderKey: '8eeee59bdab88bace6189d001f96487e',
    osName: '5PWEB',
    gainerLoserrequestCode: '5PJanGLD',
    loginRequestCode: '5PLoginV5',
    secretKey: 'ymen91bim04eimr05ebs07uo31yibb2fqhbMXIHQZ9MBBO40043Bgiieqw6iq55',
    issuer: 'https://pwapps.5paisa.com',
    apiHeaderKeyName: 'Ocp-Apim-Subscription-Key',
    getwayMarketFeedApi: 'https://pwabeta.5paisa.com/',
    gatewayBaseAPI: 'https://gateway.5paisa.com/pwaspot',
    //apiHeaderKeyValue: '4a4121b00ee741bf88faf9e85048c3ce',
    apiHeaderKeyValue: 'a347b04d1c1242d990a1ef1f46c7db07',
    paymentOcpApiHeaderKey: 'fc94a87257ba4775b188f5ac803a81f7',
    keyForSubscriptionPlans: 'a8ed3b7ead504fa8903d9c7e0d23bc61',
    authForSubscriptionPlans: '5PCRM-authtoken b01ef977ae5d658b4368dfsebe181cf5bd9deddf',
    gatewayTradeApi: 'https://gateway.5paisa.in/tradeapi',
    authgatewatApi: 'https://gateway.5paisa.com/AuthApi',
    baseTTapi: 'http://ttavatar.5paisa.in/5Paisa_Mobile_Service/Service1.svc',
    // gatewayKey: '20b999c638704f3ea252b1b5ced2eb4a',
    ocpActivation: 'a005f813e8a44ff9b8b45c61583b3820',
    gatewayKey: '93622416a8b542ba948bb2c46b0f4d50',
    diygatewayKey: 'a005f813e8a44ff9b8b45c61583b3820',
    marketFeedApiKey: '93622416a8b542ba948bb2c46b0f4d50',
    microChartTokenAPIHeader: 'aA6Y61KMFGTaekaweLS5jPGa9vXPl7/v5qV11eq8aC/IwewderzLcA==',
    microChartHeaderKey: '2284d4215515451ca7266097726d8097',
    twofaSubscriptionKey: 'a8ed3b7ead504fa8903d9c7e0d23bc61',
    webSocketTokenKeyValue: 'c89fab8d895a426d9e00db380b433027',
    jwtToken:
        'JwtToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjU0MzExNTY5Iiwicm9sZSI6IjEiLCJuYmYiOjE2NDczMjYwMTUsImV4cCI6MTY0OTkxODAxNSwiaWF0IjoxNjQ3MzI2MDE1fQ.UUQcmn7WRjl4qhELfx60zac8ttfTKSKrzFU4KRvDeEw',
    companyReturnsToken: 'a4af51382266497bb5464d95fbb2017b',
    companyReturnsBasicAuth: 'Basic aW5kaWFpbmZvbGluZVw1cGFuZHJvaWQ6MnhxUzV2L1g5Og==',
    csrftoken: 'csrftoken=bOaMGqNvTRpUvfwh70vQXznvW98xGQ3U',
    basicAuth: 'Basic QWxleGFBcGlVc2VyOiQzY2gwQXQ3MCFjZTk2',
    loggerApiUrl: 'http://localhost:68552/', // Replace with local API
    // loggerApiUrl: undefined, // Replace with local API

    // baseAPI: 'https://tradestation-uat.azure-api.net/pwaspot',
    baseAPI: 'https://gateway.5paisa.com/pwaspot',
    // baseAPINew: 'https://tradestation-uat.azure-api.net/',
    baseAPINew: 'https://gateway.5paisa.com/',
    loginBaseUrl: 'https://uatapi.5paisa.in/',
    //getwayAPI: 'https://gateway.5paisa.com/5pactivation',
    // baseAPIActivation: 'https://tradestation-uat.azure-api.net/5peaccountuat/',
    baseAPIActivation: 'https://diygateway.5paisa.com/5peaccount/',
    getMicroChartAPI: 'https://minichart.5paisa.com/chartimage/',
    getMicroChartTokenAPI: 'https://charts-auth.5paisa.com/api/ChartToken',
    // accopeningchecksumkey: '40416ZBag@Qxzheo!9477',
    accopeningchecksumkey: '40817ZXag@Exzheo!9477',
    // accopeningapiusername: 'ZavQiciaB5nsaRMMae85I6FcZQH6pElWBqXLM9cbEAw=',
    // accopeningapipassword: 'Z0ZeQ7BXlRQ2caMze7qsmlq+tcq8S5cxQJMPmZaCaSE=',
    accopeningapiusername: 'XdLJIngSRc5/lFGNrEkvV18lzz6nMZ8rTVK5m6StxJBp/dGVEGLCnS5IcrzJoR7r',
    accopeningapipassword: 'XdLJIngSRc5/lFGNrEkvVymUS3IpEXgzL0L91YPcXhFfB3N503qKStn08cXO+rRd',
    // accOpeningAPI: 'https://tradestation-uat.azure-api.net/5peaccountuat/',
    // accOpeningAuthenticationKey: 'casam3zeve4afgeqdxfeb8fce220212121',
    accOpeningAuthenticationKey: 'Sacam3aeve4afgeqdxbeb8fce220212121',
    getCompanyReturnsAPI: 'https://gateway.5paisa.com/tradeapi/company-performance',
    getPartnerDetails: 'https://paisauat.firebaseio.com/db/PWA/',
    chartToken:
        'sv=2019-12-12&st=2022-04-08T06%3A18%3A46Z&se=2022-04-08T07%3A18%3A46Z&sr=c&sp=r&sig=v9D07uibyxr0uylXgm9e9zZs746w8Pxo1cA%2B37U11Vo%3D',
    digioURL: 'https://api.digio.in/#/gateway/login/"',
    // digioCallbackUrl : "http://localhost:63555/activation/add-bank",
    digioCallbackUrl: 'https://pwaweb-uat.5paisa.com/activation/digilocker/success',
    // digioCallbackUrl: 'https://pwaweb-uat.5paisa.com/activation/add-bank',
    webSocketTokenAPI: 'https://openapi.5paisa.com/marketfeed-token/token',
    geoLocationUrl: 'https://api.ipgeolocation.io/ipgeo?apiKey=d7a00180faa14d5b89906d9710e6bfdd',
    EaccountLoginUrl: 'https://eaccount.5paisa.com/ClientLogin/VerifyClientLoginMobile?',
    paymentEndPointUrl: 'https://gateway.5paisa.com/Payment/PayIn',

    //add subscription
    subscriptionChecksumKey: 'sFQipBjWQZlpzahfukkbJDOAdF4Q3WT0pyHA+JdRvMS773WuppUSThALZCxvNJUWYACmj6xMahhxIfW0L9MKhQ==',

    //added variables
    activationEncryptionKey: 'IIFV2SPBNI11888',
    AppSource: '15',
    appSourcedBy: 'SPOT',
    fivePaisaWebApiKey: 'kahdAjhfdsd423jkoi3frDFD3vbxvzyXZ4S4dF',
    pgMerchantId: 'HDFC000005971526',
    fivePHMAC256Key: 'dHvbcgYGzvxl0Rajv/O1T2nZE2L+HTenr9o/JFEtOUUeVFikT7IqCatBGH4265y7yG4K9Pjw22UYK29FU5YWAQ==',

    fivePaisaApiAuthKey: 'NXBhaXNhOjVwYWlzYUB3ZWI=',
    firebase: {
        apiKey: 'AIzaSyCW7oaHrnZt7MmDfEEQ3Z5oEG3N7lsOJNA',
        authDomain: 'paisauat.firebaseapp.com',
        databaseURL: 'https://paisauat.firebaseio.com',
        projectId: 'paisauat',
        storageBucket: 'paisauat.appspot.com',
        messagingSenderId: '423363724376',
        appId: '1:423363724376:web:2875f473722a6a3358762d',
        measurementId: 'G-L9P0GEEKBV'
    },
    loginRedirection: 'https://login.5paisa.com',
    baseDomain: 'https://pwaweb-preprod.5paisa.com/',
    paymentBaseApi: 'https://gateway.5paisa.com/Payment/PayIn/',
    // Added By Sachin Devkar
    pwaNodeAPI: 'https://pwaprodapi.5paisa.com/api/v1/SSO/',
    pwaNodeAPIUser: 'pwaliveapi',
    pwaNodeAPIPass: 'pwaliveapi',
    ActivationURL: 'https://pwaweb.5paisa.com/activation/mobile-verification',
    accountopeningspotusername: 'jr6kYSUDFmgS3nfUGl63dW0Kxefq0URmqUlmMDK0+Bw=',
    accountopeningspotuserpassword: '5TFFze0LcBosjcljXL1RXs/vaY3zGBw9lWJ+S5eaEYE=',
    spotAuthenticationKey: 'AHxwqzO2naLu9FW758aMdobHZGdvE8p6',
    basicAuthGuard:
        'Basic anI2a1lTVURGbWdTM25mVUdsNjNkVzBLeGVmcTBVUm1xVWxtTURLMCtCdz06NVRGRnplMExjQm9zamNsalhMMVJYcy92YVkzekdCdzlsV0orUzVlYUVZRT0=',
    accOpeningAuthenticationSpotKey: 'AHxwqzO2naLu9FW758aMdobHZGdvE8p6',
    accopeningchecksumkeyspot: 'CqW%MsuNVo!Gghlq',
    getBankdetailsVariable: 'SIPAmount',
    updateEmailVersion: 'UpdateNewEmailMobile_V2',
    experianLogUrl: 'https://pwaprodapi.5paisa.com/api/v1/SSO/CaptureAPIDetails',
    iosspotchecksumkey: 'gHqBe@y!M%@jNlLd',
    iosaccountopeningspotusername: 'ix2OYeXODrPZukzBqyrfyspPmYlua530jU5o3thLftk=',
    iosaccountopeningspotpassword: '0YgAtI4tdAdkb6uqk9sbgLpn8kiYaqhZPGCJ+P4a8PI=',
    iosSpotAuthenticationKey: 'YbkQXgL9eMbRaZK7s3uPkiDaMG2cdBrW',
    encodingKey: 'ymen91bim04eimr05ebs07uo31yibb2fqhbMXIHQZ9MBBO40043Bgiieqw6iq55',
    redirectUrl: 'https://pwappspreprod.5paisa.com/sso',
    IsRazorpayActive: 'Y',
    authgatewayOcpkey: '3f7b4ee1ba4b47d69500d3ec26f06650',
    ipoGatewayKey: '3694a376dc514d3388a22372eced4988',
    userIdMarketFeed: 'ZHAaaQdSd6J',
    passwordMarketFeed : 'tmDDnp7hYaZ',
    swarajAuthBaseUrl : 'https://swarajauthpreprod.5paisa.com/AuthenticateService/Service1.svc/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
